const CryptoJS = require('./aes.js');
const JSEncrypt = require('./jsencrypt.js');

// KEY随机数
export const randomString = () => {
  let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    value = "";
  for (let i = 0; i < 16; i++) value += t.charAt(Math.floor(Math.random() * a));
  return value
}

// RSA加密
export const encryptRsa = (random) => {
  let encryptor = new JSEncrypt.JSEncrypt();
  encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----\n' + 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjfPDcJUDyRUPt3WagOFe4moz/pfGUQsiivVTV7O9V5d6KSo9zmELJqdJ1oc3cigaYsUxJJTjibEjMKP/9t9v2NBc0fXQDaT65ls3EplUyJMX1S30F3peCy4WIZ/eIEomL+tXsdW3TvHFMPuhRLjIl9e/M1J7wDsxIOvliBoTwOYKDkUKYt0u5bFKQaXLSFjR3TtfqxWU8GpMSaMkREf8LyW0Nx03BjH7pQCw+oxpMiNqLDAGDwTsYnNp8Qwogb56k3y3jwn6M2m6TAnMeRv+YBcFbmvIE7jFXmyxotJ6z3oxj5o+TzqprrZzR/2TeI6eXom04k8TYK4kmtk31cLt8QIDAQAB' + '\n-----END PUBLIC KEY-----');
  return encryptor.encrypt(random);
}

// RSA解密
export const decryptRsa = (random) => {
  let encryptor = new JSEncrypt.JSEncrypt();
  encryptor.setPrivateKey('-----BEGIN PRIVATE KEY-----\n' + localStorage.getItem('priKey') + '\n-----END PRIVATE KEY-----')
  return encryptor.decrypt(random)
}

// AES加密
export const encryptAes = (word, random) => {
  let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(word), CryptoJS.enc.Utf8.parse(random), {
    iv: CryptoJS.enc.Utf8.parse('Njymwkj202106166'),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

// AES解密
export const decryptAes = (word, random) => {
  let decrypt = CryptoJS.AES.decrypt(word, CryptoJS.enc.Utf8.parse(random), {
    iv: CryptoJS.enc.Utf8.parse('Njymwkj202106166'),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}

// 生成签名
export const generateSign = (word, random) => {
  return CryptoJS.MD5(word + '&key=' + random).toString().toUpperCase()
}