export function goExit(value) {
    window.AlipayJSBridge.call(value);
}

export function goStartApp(value) {
    window.AlipayJSBridge.call('startApp', {
        appId: '30603024',
        param: {
            type: "3",
            tokenId: value,
            paySystem: "",
            payType: "1111",
            webviewURL: "",
            remark: "",
            showProgress: 'NO',
            backBehavior: 'back'
        },
    });
}