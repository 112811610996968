<template>
  <div style="min-height: 100vh;background: #fff">
    <div class="noticeDetail-wrap">
      <h1>{{ noticeInfo.title }}</h1>
      <div v-html="noticeInfo.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      noticeInfo: JSON.parse(this.$route.query.info)
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style>
.noticeDetail-wrap {
  padding: 16px;
  box-sizing: border-box;
}

.noticeDetail-wrap h1 {
  margin-top: 0;
  font-size: 18px;
}
</style>