<template>
  <div style="min-height: 100vh;background: #fff">
    <div class="amount-wrap">
      <h1>选择捐赠金额</h1>
      <div class="amount-w-main">
        <div :class="{ 'amount-w-m-active': item.value === amount }" v-for="(item, index) in amountList" :key="index"
          @click="amount = item.value; inputAmount = ''">
          <span>{{ item.label }}</span>
          <p v-if="item.value === amount">
            <van-icon name="success" color="#000" />
          </p>
        </div>
      </div>
      <div class="amount-w-input">
        <van-field type="number" v-model="inputAmount" placeholder="其他金额" @focus="amount = ''" />
        <span style="color: #999">元</span>
      </div>
    </div>
    <div class="message-wrap">
      <div class="message-w-title">
        <h1>我的祈福语</h1>
        <div class="message-w-t-right" @click="portal_bless_list">
          <van-icon name="replay" />
          <span style="margin-left: 3px">随机祈福语</span>
        </div>
      </div>
      <div class="message-w-input">
        <textarea rows="3" placeholder="写一句祈福语吧~（最多30个字符）" v-model="remark" maxlength="30"></textarea>
        <span>{{ remark.length }}/30</span>
      </div>
      <van-button color="#ffa00d" size="large" @click="portal_trade_pay">确认支付</van-button>
    </div>
  </div>
</template>

<script>
import { decryptAes, randomString } from "@/http/encrypt";
import { portal_bless_list, portal_trade_pay } from "@/http/api";

export default {
  data() {
    return {
      // 浏览器类型
      browser: '',
      // 捐赠金额
      amount: '',
      inputAmount: '',
      // 金额列表
      amountList: [
        { label: '10元', value: '10' },
        { label: '50元', value: '50' },
        { label: '66元', value: '66' },
        { label: '88元', value: '88' },
        { label: '100元', value: '100' },
        { label: '200元', value: '200' },
      ],
      // 祈福语
      remark: '',
      number: -1
    }
  },

  methods: {
    // 支付下单
    portal_trade_pay() {
      let _this = this;
      if (_this.amount > 0 || _this.inputAmount > 0) {
        const random = randomString();
        portal_trade_pay({
          ck: 'portal_trade_pay',
          dealAmt: _this.amount || _this.inputAmount,
          channel: _this.browser,
          feeType: localStorage.getItem("feeType"),
          templeNo: localStorage.getItem("templeNo"),
          remark: _this.remark
        }, random).then(response => {
          if (response.data.code === 0) {
            let returnParameter = JSON.parse(decryptAes(response.data.data, random));
            if (_this.browser === 'WECHAT_PAY' || _this.browser === 'ALI_PAY') {
              window.location.href = returnParameter.oneQRForAll;
            } else if (_this.browser === 'ABC_PAY') {
              _this.inputAmount = '';
              let bridge = require('../AlipayJSBridge');
              let token = returnParameter.oneQRForAll.split('=')[1];
              bridge.goStartApp(token);
            }
          }
        });
      } else {
        this.$toast("请选择或输入金额")
      }
    },
    // 祈福语列表
    portal_bless_list() {
      let _this = this;
      const random = randomString();
      portal_bless_list({
        ck: 'portal_bless_list',
        value: localStorage.getItem("templeNo")
      }, random).then(response => {
        if (response.data.code === 0) {
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          if (returnParameter.length > _this.number + 1) {
            _this.number++;
          } else {
            _this.number = 0;
          }
          _this.remark = returnParameter[_this.number].bless;
        }
      });
    },
  },
  mounted() {
    let _this = this;
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      _this.browser = 'WECHAT_PAY'
    } else if (ua.match(/AliApp/i) == 'aliapp') {
      _this.browser = 'ALI_PAY'
    } else if (ua.match(/bankabc/i) == 'bankabc') {
      _this.browser = 'ABC_PAY'
    }
  }
}
</script>

<style>
.amount-wrap,
.message-wrap {
  padding: 16px;
  box-sizing: border-box;
}

.message-wrap .message-w-title h1 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.message-wrap .message-w-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.message-wrap .message-w-title .message-w-t-right {
  font-size: 18px;
  color: #ffa00d;
  display: flex;
  align-items: center;
}

.amount-wrap h1 {
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 15px 0;
}

.amount-wrap .amount-w-main {
  display: flex;
  flex-wrap: wrap;
}

.amount-wrap .amount-w-main div {
  width: calc(33.1% - 6px);
  height: 55px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid #ffa00d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #333;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  background-color: #ffa00d;
}

.amount-w-main div:nth-child(3),
.amount-w-main div:nth-child(6) {
  margin-right: 0;
}

.amount-wrap .amount-w-m-active {
  background: #fff !important;
  border-color: #ffa00d !important;
}

.amount-wrap .amount-w-m-active p {
  background: #ffa00d;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 2px 4px;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  color: #fff;
  font-size: 14px;
}

.amount-wrap .amount-w-input {
  display: flex;
  height: 45px;
  align-items: center;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 18px;
}

.amount-wrap .amount-w-input .van-cell {
  padding: 0;
}

.amount-wrap .amount-w-input .van-cell::after {
  border: 0;
}

.amount-wrap .amount-w-input input {
  height: 100%;
  width: 100%;
  border: 0;
  font-size: 17px;
  padding: 0;
}

.message-wrap .message-w-input {
  border-radius: 4px;
  overflow: hidden;
  padding: 12px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  margin-bottom: 16px;
  position: relative;
}

.message-wrap .message-w-input span {
  position: absolute;
  right: 12px;
  bottom: 12px;
  font-size: 14px;
  color: #ccc;
}

.message-wrap .message-w-input textarea {
  width: 100%;
  resize: none;
  border: 0;
  font-size: 18px;
  padding: 0;
}

.van-button {
  height: 55px !important;
}

.van-button__text {
  color: #000 !important;
  font-size: 20px;
}

input::placeholder,
textarea::placeholder {
  color: #ccc;
}
</style>