import axios from 'axios';
import {Toast} from 'vant';
import router from '../router';
import {generateSign, encryptAes, encryptRsa} from './encrypt'

// 图片地址
let imgUrl = 'https://pic.zjxhzjgl.top/';
// 请求地址
// http://www.zjxhzjgl.cn/dispatcher/
// let url = process.env.NODE_ENV === 'production' ? 'http://47.103.94.222:11001/dispatcher/' : 'api/';
// let url = process.env.NODE_ENV === 'production' ? 'http://121.36.254.151:13004/dispatcher/' : 'api/';
let url = process.env.NODE_ENV === 'production' ? 'https://www.zjxhzjgl.top/dispatcher/' : 'api/';

const http = (params) => {
    return new Promise((resolve) => {
        if (params.showLoading) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
        }
        let A = `appId=23fb57374dfd47379987bd09719c69d0&body=${JSON.stringify(params.data)}&nonce=${params.random}&timestamp=${new Date().getTime()}`;
        let B = `${generateSign(A, params.random)}`;
        let C = `${A}&sign=${B}`;
        let header = {
            "Content-Type": 'application/json;',
            "Client-Id": 'portal',
            "Client-Secret": encryptRsa(params.random)
        };
        if (params.token) {
            header.Authorization = localStorage.getItem('token');
        }
        axios({
            url: url + 'dispatcher',
            method: params.method,
            data: {
                params: encryptAes(C, params.random)
            },
            headers: header,
        }).then(response => {
            Toast.clear();
            resolve(response);
            if (response.data.code !== 0) {
                Toast(response.data.message);
            }
            if (response.statusCode === 401) {
                localStorage.clear();
                router.replace({name: 'login'});
            }
        }).catch(() => {
            Toast.fail('接口请求错误');
        });
    });
};

export {
    http, imgUrl
}