import {
    http
} from './request.js';

// 轮播列表
const portal_banner_list = (data, random) => {
    return http({
        method: 'POST',
        data: data,
        random: random,
        showLoading: true,
        token: false
    });
};

// 寺庙详情
const portal_temple_detail = (data, random) => {
    return http({
        method: 'POST',
        data: data,
        random: random,
        showLoading: true,
        token: false
    });
};

// 公告列表
const portal_notice_list = (data, random) => {
    return http({
        method: 'POST',
        data: data,
        random: random,
        showLoading: true,
        token: false
    });
};

// 支付下单
const portal_trade_pay = (data, random) => {
    return http({
        method: 'POST',
        data: data,
        random: random,
        showLoading: true,
        token: false
    });
};

// 交易列表
const portal_trade_list_page = (data, random) => {
    return http({
        method: 'POST',
        data: data,
        random: random,
        showLoading: true,
        token: false
    });
};

// 祈福语列表
const portal_bless_list = (data, random) => {
    return http({
        method: 'POST',
        data: data,
        random: random,
        showLoading: true,
        token: false
    });
};

export {
    portal_banner_list,
    portal_temple_detail,
    portal_notice_list,
    portal_trade_pay,
    portal_trade_list_page,
    portal_bless_list
}