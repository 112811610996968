<template>
  <div>
    <van-notice-bar left-icon="volume-o" mode="link" @click="$router.push({ name: 'notice' })">
      <van-swipe vertical class="notice" :autoplay="5000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in noticeList" :key="index">{{ item.title }}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <div class="banner">
      <!-- <vue-seamless-scroll :data="donateList" :class-option="seamlessOption" class="banner-seamless"
        :style="{ width: liWidth || '100%' }">
        <ul ref="ul">
          <li v-for="(item, index) in donateList" :key="index">
            <span>
              <img src="../assets/1.png" width="100%" height="100%">
            </span>
            <span>{{ item.name }}</span>
            <span>捐赠了{{ item.dealAmt }}元</span>
            <span>{{ item.paySuccessTime }}</span>
          </li>
        </ul>
      </vue-seamless-scroll> -->
      <div ref="img">
        <van-swipe :autoplay="5000" :show-indicators="false" style="background: #f5f5f5;font-size: 0"
          :style="{ 'height': imgHeight + 'px' }">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img :src="imgUrl + item.imageUrl" width="100%">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!--    <div class="statistics">{{ donateNum }}人也捐赠过</div>-->
    <div class="title">{{ templeInfo.templeName }}</div>
    <div class="illustrate" v-html="templeInfo.introduction"></div>
    <div class="button">
      <van-button color="#ffa00d" size="large"
        @click="$route.query.feeType == 1 ? $router.push({ name: 'donate' }) : dialogShow = true">{{$route.query.feeType== 1 ? '我要捐款' : '我要支付'}}</van-button>
    </div>
    <van-dialog v-model="dialogShow" title="请输入支付金额" :showConfirmButton="false" style="font-size: 18px;">
      <div class="dialog">
        <van-icon name="cross" size="24px" @click="dialogShow = false" />
        <div class="input">
          <van-field type="number" v-model="inputAmount" :autofocus="true" placeholder="支付金额" />
          <span style="color: #999">元</span>
        </div>
        <van-button color="#ffa00d" block @click="portal_trade_pay">确认支付</van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { portal_banner_list, portal_temple_detail, portal_notice_list, portal_trade_list_page, portal_trade_pay } from "@/http/api";
import { randomString, decryptAes } from '../http/encrypt'
import { imgUrl } from "@/http/request";

export default {
  data() {
    return {
      imgUrl: imgUrl,
      liWidth: 0,
      imgHeight: 0,
      // 公告列表
      noticeList: [],
      // 轮播图
      bannerList: [],
      // 寺庙信息
      templeInfo: {},
      // 捐赠列表
      donateList: [],
      donateNum: 0,
      // 捐款金额
      inputAmount: '',
      // 捐款弹框
      dialogShow: false,
      // 浏览器类型
      browser: '',
    }
  },
  computed: {
    seamlessOption() {
      return {
        singleHeight: 37,
        waitTime: 3000
      }
    }
  },
  methods: {
    // 支付下单
    portal_trade_pay() {
      let _this = this;
      const random = randomString();
      portal_trade_pay({
        ck: 'portal_trade_pay',
        dealAmt: _this.inputAmount,
        channel: _this.browser,
        feeType: _this.$route.query.feeType,
        templeNo: _this.$route.query.templeNo,
        remark: _this.remark
      }, random).then(response => {
        if (response.data.code === 0) {
          _this.dialogShow = false;
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          if (_this.browser === 'WECHAT_PAY' || _this.browser === 'ALI_PAY') {
            window.location.href = returnParameter.oneQRForAll;
          } else if (_this.browser === 'ABC_PAY') {
            _this.inputAmount = '';
            let bridge = require('../AlipayJSBridge');
            let token = returnParameter.oneQRForAll.split('=')[1];
            bridge.goStartApp(token);
          }
        }
      });
    },
    // 轮播列表
    portal_banner_list() {
      let _this = this;
      const random = randomString();
      portal_banner_list({
        ck: 'portal_banner_list',
        value: localStorage.getItem("templeNo")
      }, random).then(response => {
        if (response.data.code === 0) {
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          _this.bannerList = returnParameter;
          setTimeout(() => {
            let liHeightArr = [];
            Array.prototype.slice.call(_this.$refs.img.getElementsByTagName('img')).forEach(li => {
              liHeightArr.push(li.offsetHeight);
            });
            _this.imgHeight = Math.min(...liHeightArr) <= 300 ? 300 : Math.min(...liHeightArr);
          }, 100);
        }
      });
    },
    // 寺庙详情
    portal_temple_detail() {
      let _this = this;
      const random = randomString();
      portal_temple_detail({
        ck: 'portal_temple_detail',
        value: localStorage.getItem("templeNo")
      }, random).then(response => {
        if (response.data.code === 0) {
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          _this.templeInfo = returnParameter;
        }
      });
    },
    // 公告列表
    portal_notice_list() {
      let _this = this;
      const random = randomString();
      portal_notice_list({
        ck: 'portal_notice_list',
        value: localStorage.getItem("templeNo")
      }, random).then(response => {
        if (response.data.code === 0) {
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          _this.noticeList = returnParameter;
        }
      });
    },
    // 交易列表
    portal_trade_list_page() {
      let _this = this;
      const random = randomString();
      portal_trade_list_page({
        ck: 'portal_trade_list_page',
        pageNo: '1',
        pageSize: '5',
        search: {
          templeNo: localStorage.getItem("templeNo")
        }
      }, random).then(response => {
        if (response.data.code === 0) {
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          _this.donateNum = returnParameter.totalRows;
          _this.donateList = returnParameter.rows.map(item => {
            item.name = '有缘人';
            item.paySuccessTime = _this.timeDiff(item.paySuccessTime.replace(/-/g, "/"));
            return item;
          });
          setTimeout(() => {
            let liWidthArr = [];
            Array.prototype.slice.call(_this.$refs.ul.getElementsByTagName('li')).forEach(li => {
              liWidthArr.push(li.offsetWidth);
            });
            _this.liWidth = Math.max(...liWidthArr) + 25 + 'px';
          }, 100)
        }
      });
    },
    timeDiff(time) {
      let result = '';
      let dateTimeStamp = new Date(time).getTime();
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let month = day * 30;
      let now = new Date().getTime();
      let diffValue = now - dateTimeStamp;
      if (diffValue < 0) {
        return;
      }
      let monthC = diffValue / month;
      let weekC = diffValue / (7 * day);
      let dayC = diffValue / day;
      let hourC = diffValue / hour;
      let minC = diffValue / minute;
      if (monthC >= 1) {
        result = "" + parseInt(monthC) + "月前";
      } else if (weekC >= 1) {
        result = "" + parseInt(weekC) + "周前";
      } else if (dayC >= 1) {
        result = "" + parseInt(dayC) + "天前";
      } else if (hourC >= 1) {
        result = "" + parseInt(hourC) + "小时前";
      } else if (minC >= 1) {
        result = "" + parseInt(minC) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    }
  },
  mounted() {
    let _this = this;
    if (_this.$route.query.templeNo) {
      localStorage.setItem("templeNo", _this.$route.query.templeNo);
    }
    if (_this.$route.query.feeType) {
      localStorage.setItem("feeType", _this.$route.query.feeType);
    }
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      _this.browser = 'WECHAT_PAY'
    } else if (ua.match(/AliApp/i) == 'aliapp') {
      _this.browser = 'ALI_PAY'
    } else if (ua.match(/bankabc/i) == 'bankabc') {
      _this.browser = 'ABC_PAY'
    }
    _this.portal_banner_list();
    _this.portal_temple_detail();
    _this.portal_notice_list();
    // _this.portal_trade_list_page();
  }
}
</script>

<style>
.notice {
  height: 40px;
  line-height: 40px;
}

.statistics {
  font-size: 13px;
  line-height: 13px;
  padding: 8px 16px;
  color: #fff;
  box-sizing: border-box;
  letter-spacing: .5px;
  background: #ffa00d;
}

.title {
  padding: 16px;
  box-sizing: border-box;
  font-size: 22px;
  color: #262c2c;
  font-weight: 500;
}

.illustrate {
  padding: 0 16px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 110px;
}

.illustrate img {
  width: 100%;
}


.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.banner .banner-seamless {
  height: 112px;
  overflow: hidden;
  position: absolute;
  bottom: 16px;
  padding-left: 16px;
  box-sizing: border-box;
  z-index: 99;
}

.banner .banner-seamless ul li {
  padding: 5px 8px 5px 6px;
  box-sizing: border-box;
  border-radius: 30px;
  font-size: 14px;
  line-height: 12px;
  margin-top: 7px;
  background: rgba(0, 0, 0, .5);
  display: inline-flex;
  align-items: center;
}

.banner .banner-seamless ul li span:nth-child(1) {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
  border: 1.5px solid #4fc08d;
  box-sizing: border-box;
  margin-right: 8px;
}

.banner .banner-seamless ul li span:nth-child(2) {
  color: #4fc08d;
  font-weight: 400;
  margin-right: 10px;
}

.banner .banner-seamless ul li span:nth-child(3) {
  color: #fff;
  font-weight: 400;
  margin-right: 10px;
}

.banner .banner-seamless ul li span:nth-child(4) {
  color: #ccc;
  font-weight: 400;
}

.button {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 16px 30px;
  box-sizing: border-box;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  z-index: 9;
}

.button .van-button__text {
  color: #000 !important;
}

.dialog {
  padding: 20px;
  box-sizing: border-box;
}

.dialog .van-button__text {
  color: #000 !important;
}

.van-dialog {
  border-radius: 6px !important;
}

.van-dialog .van-icon {
  position: absolute;
  top: 25px;
  right: 20px;
  color: #999;
}

.dialog .input {
  display: flex;
  height: 50px;
  align-items: center;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 18px;
  margin-bottom: 15px;
}

.dialog .input input::placeholder {
  font-size: 18px;
}

.dialog .input .van-cell {
  padding: 0;
}

.dialog .input .van-cell::after {
  border: 0;
}

.dialog .input input {
  height: 100%;
  width: 100%;
  border: 0;
  font-size: 15px;
  padding: 0;
}

.van-button {
  height: 55px !important;
}

.van-button__text {
  color: #000 !important;
  font-size: 20px;
}

input::placeholder,
textarea::placeholder {
  color: #ccc;
}
</style>