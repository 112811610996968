<template>
  <div style="min-height: 100vh;background: #fff">
    <div class="notice-wrap">
      <div class="notice-w-item" v-for="(item,index) in noticeList" :key="index"
           @click="goDetail(item)">
        <span class="van-ellipsis">{{ item.title }}</span>
        <span>{{ item.createdTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {decryptAes, randomString} from "@/http/encrypt";
import {portal_notice_list} from "@/http/api";

export default {
  data() {
    return {
      // 公告列表
      noticeList: []
    }
  },
  methods: {
    // 公告列表
    portal_notice_list() {
      let _this = this;
      const random = randomString();
      portal_notice_list({
        ck: 'portal_notice_list',
        value: localStorage.getItem("templeNo")
      }, random).then(response => {
        if (response.data.code === 0) {
          let returnParameter = JSON.parse(decryptAes(response.data.data, random));
          _this.noticeList = returnParameter.map(item => {
            item.createdTime = item.createdTime.split(' ')[0];
            return item;
          });
        }
      });
    },
    // 跳转详情
    goDetail(item) {
      let _this = this;
      _this.$router.push({name: 'noticeDetail', query: {info: JSON.stringify(item)}});
    }
  },
  mounted() {
    let _this = this;
    _this.portal_notice_list();
  }
}
</script>

<style>

.notice-w-item {
  font-size: 14px;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: .5px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  color: #333;
  white-space: nowrap;
}

.notice-w-item span:last-child {
  color: #999;
  font-weight: 300;
  margin-left: 10px;
}
</style>