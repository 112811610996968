import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import donate from '@/components/donate'
import notice from '@/components/notice'
import noticeDetail from '@/components/noticeDetail'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
            meta: {
                title: '简介',
            }
        },
        {
            path: '/donate',
            name: 'donate',
            component: donate,
            meta: {
                title: '捐赠',
            }
        },
        {
            path: '/notice',
            name: 'notice',
            component: notice,
            meta: {
                title: '公告',
            }
        },
        {
            path: '/noticeDetail',
            name: 'noticeDetail',
            component: noticeDetail,
            meta: {
                title: '详情',
            }
        },
    ]
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve,
        onReject)
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next()
});

export default router;
